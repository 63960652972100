define("discourse/plugins/kista_bbb/initializers/kista-bbb", ["exports", "discourse/lib/plugin-api", "discourse/lib/show-modal", "discourse-common/lib/icon-library", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _pluginApi, _showModal, _iconLibrary, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.openedwindows = _exports.default = void 0;
  //import { isAppWebview } from "discourse/lib/utilities";
  //import { isAppWebview, isAppleDevice } from "discourse/lib/utilities";
  // import { isAppWebview, isAppleDevice, iOSWithVisualViewport, isiPad, safariHacksDisabled, isiOSPWA } from "discourse/lib/utilities";

  let openedwindows = _exports.openedwindows = [];
  const title_attr = title => ({
    "aria-label": title || null,
    title: title || null
  });
  function launchBBB($elem, tab, instant) {
    const data = $elem.data(),
      // der erste Buchstabe von data()-Feldern wird von Discourse immer lowercase gesetzt. Feldnamen müssen mehr als einen Buchstaben haben, sonst kommen die Variablen nicht an
      site = Discourse.__container__.lookup("site:main");
    // wenn site.mobileView dann optional als iframe
    // wenn isAppWebview() dann eher verpflichtend window.location.href=res.url

    if (1) {
      $(window).off("message.kista-bbb").on("message.kista-bbb", e => {
        var $srcd = $("iframe").filter((num, dom) => dom.contentWindow === e.originalEvent.source).parents(".kista-bbb-float"),
          abtn = $srcd.find(".kista-bbb-audiobtn");
        if ($srcd.length) {
          switch (e.originalEvent.data.response) {
            case "selfMuted":
              if (!abtn.hasClass("kista-bbb-nomic")) {
                abtn.html((0, _iconLibrary.iconHTML)("microphone-slash")).attr(title_attr("Stummgeschaltet"));
              }
              break;
            case "selfUnmuted":
              if (!abtn.hasClass("kista-bbb-nomic")) {
                abtn.html((0, _iconLibrary.iconHTML)("microphone")).attr(title_attr("Mikrofon aktiv"));
              }
              break;
            case "joinedAudio":
              abtn.html((0, _iconLibrary.iconHTML)("volume-off")).removeClass("kista-bbb-nomic").attr(title_attr("Nur Zuhören"));
              ifrdom.contentWindow.postMessage("c_mute_status", "*");
              break;
            case "notInAudio":
              abtn.html((0, _iconLibrary.iconHTML)("volume-mute")).addClass("kista-bbb-nomic").attr(title_attr("Ton deaktiviert"));
              break;
            case "quit":
              $("html").removeClass("kista-bbb-float-maximized");
              $srcd.remove();
              break;
          }
          console.log($srcd.find(".kista-bbb-windowtitle").text(), e.originalEvent.data.response);
        } else {
          var srcw = openedwindows.filter((win, num) => win === e.originalEvent.source).shift();
          if (srcw) {
            switch (e.originalEvent.data.response) {
              case "quit":
                srcw.close();
                break;
            }
            console.log("ext Tab", e.originalEvent.data.response);
          }
        }
      });
    } else {}
    var floatremoved = 0;
    if ($('div.kista-bbb-float').length) {
      $('div.kista-bbb-float').remove();
      floatremoved = 1;
    }
    if (!floatremoved || tab) {
      let isiphone = testios().ios,
        isandroid = $(window).height() >= $(window).width() * 1.2;
      if (tab && !isiphone) {
        if (!instant) {
          //var tabwin = window.open("about:blank","_blank");
          var tabwin = window.open();
          openedwindows.push(tabwin);
          $(tabwin.document).prop("title", data["titel"]);
          $(tabwin.document.body).css({
            "background-color": "#16384F",
            "margin": 0
          });
          var d = $('<div>').appendTo($(tabwin.document.body));
          d.css({
            "background-image": 'url("/plugins/kistaBBB/bubbles.svg")',
            "background-size": "auto 60%",
            "background-repeat": "no-repeat",
            "background-position-x": "100%",
            width: "100%",
            height: "100%"
          });
        }
      } else if (!isandroid) {
        var d = $('<div>').addClass("kista-bbb-float");
        "nw,ne,se,sw".split(",").forEach(corner => d.append($('<div>').addClass(`kista-bbb-resize kista-bbb-resize-${corner}`).on("mousedown", e => {
          e.preventDefault();
          d.find('iframe').css('pointer-events', 'none');
          var activecorner = $(e.target),
            cursor = activecorner.css("cursor"),
            gripcoords = {
              x: e.originalEvent.clientX,
              y: e.originalEvent.clientY
            },
            start = d[0].getBoundingClientRect(),
            newdim = {
              left: start.left,
              top: start.top,
              width: start.width,
              height: start.height
            },
            bounds = {
              width: d.css('min-width').replace(/[^0-9]/g, "") || 0,
              height: d.css('min-height').replace(/[^0-9]/g, "") || 0
            };
          bounds.left = start.left + start.width - bounds.width;
          bounds.top = start.top + start.height - bounds.height;
          activecorner.addClass("kista-bbb-resize-active");
          $('body').css('cursor', cursor).on("mousemove", e => {
            var delta = {
              x: e.originalEvent.clientX - gripcoords.x,
              y: e.originalEvent.clientY - gripcoords.y
            };
            switch (corner[0]) {
              case "n":
                newdim.height = start.height - delta.y;
                newdim.top = start.top + delta.y;
                break;
              case "s":
                newdim.height = start.height + delta.y;
                break;
            }
            switch (corner[1]) {
              case "w":
                newdim.width = start.width - delta.x;
                newdim.left = start.left + delta.x;
                break;
              case "e":
                newdim.width = start.width + delta.x;
                break;
            }
            ["left", "top"].forEach(x => {
              newdim[x] = Math.min(bounds[x], newdim[x]);
            });
            ["width", "height"].forEach(x => {
              newdim[x] = Math.max(bounds[x], newdim[x]);
            });
            d.css(newdim);
          }).on("mouseup", e => {
            $('body').off("mousemove").css('cursor', 'auto');
            activecorner.removeClass("kista-bbb-resize-active");
            d.find('iframe').css('pointer-events', 'auto');
          });
        })));
        var $ifr = $('<iframe>').attr({
            "allowfullscreen": "true",
            "allow": "camera; microphone; display-capture; fullscreen; speaker"
          }),
          ifrdom = $ifr[0];
        var $volbtn = $('<div class="kista-bbb-controlbtn kista-bbb-audiobtn kista-bbb-nomic">').html((0, _iconLibrary.iconHTML)("volume-mute")).attr(title_attr("Ton deaktiviert")).on("click", e => ifrdom.contentWindow.postMessage("c_mute", "*")),
          $minbtn = $('<div class="kista-bbb-controlbtn">').html((0, _iconLibrary.iconHTML)("chevron-down")).attr(title_attr("Minimieren")).on("click", e => {
            $("html").removeClass("kista-bbb-float-maximized");
            d.removeClass("kista-bbb-float-maximized");
            d.toggleClass("kista-bbb-float-minimized");
            var minimized = d.hasClass("kista-bbb-float-minimized");
            $(e.currentTarget).html((0, _iconLibrary.iconHTML)(`chevron-${minimized ? "up" : "down"}`)).attr(title_attr(minimized ? "Wiederherstellen" : "Minimieren"));
            $maxbtn.html((0, _iconLibrary.iconHTML)("discourse-expand")).attr(title_attr("Maximieren"));
          }),
          $maxbtn = $('<div class="kista-bbb-controlbtn">').html((0, _iconLibrary.iconHTML)("discourse-expand")).attr(title_attr("Maximieren")).on("click", e => {
            $("html").toggleClass("kista-bbb-float-maximized");
            d.toggleClass("kista-bbb-float-maximized");
            d.removeClass("kista-bbb-float-minimized");
            var maximized = d.hasClass("kista-bbb-float-maximized");
            $(e.currentTarget).html((0, _iconLibrary.iconHTML)(`discourse-${maximized ? "compress" : "expand"}`)).attr(title_attr(maximized ? "Wiederherstellen" : "Maximieren"));
            $minbtn.html((0, _iconLibrary.iconHTML)("chevron-down")).attr(title_attr("Minimieren"));
          }),
          $fulbtn = $('<div class="kista-bbb-controlbtn">').html((0, _iconLibrary.iconHTML)("expand")).attr(title_attr("Vollbild")).on("click", e => {
            d.removeClass("kista-bbb-float-minimized");
            fullscreenRequest(ifrdom);
          }),
          $clsbtn = $('<div class="kista-bbb-controlbtn">').html((0, _iconLibrary.iconHTML)("times")).attr(title_attr("Schließen")).on("click", e => {
            $("html").removeClass("kista-bbb-float-maximized");
            d.remove();
          });
        var t = $('<div>').addClass("kista-bbb-floathead").append($('<div class="kista-bbb-windowtitle">').text(data["titel"])).append($('<div class="kista-bbb-windowcontrols">').append($volbtn).append($minbtn).append($maxbtn).append($fulbtn).append($clsbtn)).appendTo(d);
        d.append($('<div>').addClass("kista-bbb-ifdiv").append($ifr));
        t.on("mousedown", e => {
          e.preventDefault();
          var p = e.target.getBoundingClientRect();
          d.find('iframe').css('pointer-events', 'none');
          var gripcoords = {
            x: e.originalEvent.x - p.left,
            y: e.originalEvent.y - p.top
          };
          $('body').on("mousemove", e => {
            d.css({
              left: e.originalEvent.clientX - gripcoords.x,
              top: e.originalEvent.clientY - gripcoords.y
            });
          }).on("mouseup", e => {
            $('body').off("mousemove");
            d.find('iframe').css('pointer-events', 'auto');
          });
        });
        t.find(".kista-bbb-controlbtn").on("mousedown", e => {
          e.preventDefault();
          e.stopPropagation();
        });
        d.appendTo('body').css({
          left: ($(window).width() - d.width()) / 2,
          top: ($(window).height() - d.height()) / 2
        }); //.css($elem.parent().offset());
      } else if (isandroid && 0) {
        /* MOBIL */
        var d = $('<div>').addClass("kista-bbb-mobilefloat");
        var $ifr = $('<iframe>').attr({
            "allowfullscreen": "true",
            "allow": "camera; microphone; display-capture; fullscreen; speaker"
          }),
          ifrdom = $ifr[0];
        d.append($("<div>").addClass("handlebar-outer").append($("<div>").addClass("handlebar"))).on("click", e => {
          let $pdiv = $(e.target).parents(".kista-bbb-mobilefloat");
          if ($pdiv.position().top == 55) {
            $pdiv.css("top", $(window).height() - 60);
            $pdiv.find(".handlebar-title").show(1000);
          } else {
            $pdiv.css("top", 55);
            $pdiv.find(".handlebar-title").hide(1000);
          }
        });
        var $clsbtn = $("<div>").addClass("handlebar-close").on("click", e => d.remove());
        d.append($("<div>").addClass("handlebar-title").text(data["titel"]).append($clsbtn).css("display", "none"));
        // var t=$('<div>').addClass("kista-bbb-floathead")
        //   .append($('<div class="kista-bbb-windowtitle">').text(data["titel"]))
        //   .append($('<div class="kista-bbb-windowcontrols">').append($volbtn).append($minbtn).append($maxbtn).append($fulbtn).append($clsbtn))
        //  .appendTo(d);
        d.append($('<div>').addClass("kista-bbb-ifdiv").append($ifr));
        // t.on("mousedown", e => {
        //   e.preventDefault();
        //   var p=e.target.getBoundingClientRect();
        //   d.find('iframe').css('pointer-events','none');
        //   var gripcoords={x:e.originalEvent.x-p.left, y:e.originalEvent.y-p.top};
        //   $('body')
        //     .on("mousemove", e => { d.css({left:e.originalEvent.clientX-gripcoords.x, top:e.originalEvent.clientY-gripcoords.y}); })
        //     .on("mouseup", e => { $('body').off("mousemove"); d.find('iframe').css('pointer-events','auto'); });
        // });
        // t.find(".kista-bbb-controlbtn").on("mousedown",e=>{e.preventDefault();e.stopPropagation()});
        // d.appendTo('body').css({left:($(window).width()-d.width())/2,top:($(window).height()-d.height())/2}); //.css($elem.parent().offset());
        d.appendTo('body').css({
          left: 0,
          height: $(window).height() - 55
        }); //.css($elem.parent().offset());
        d.css("top", 55);
        //https://stackoverflow.com/questions/16348031/disable-scrolling-when-touch-moving-certain-element
      } else {/* MOBIL */
      }
      var createparams = {
        "titel": data["titel"],
        "pp": data["pp"],
        "mod": 1
      };
      if (data.hasOwnProperty("sr")) {
        createparams["sr"] = data["sr"];
      }
      (0, _ajax.ajax)("/kista-bbb/create.json", {
        type: "POST",
        data: createparams
      }).then(res => {
        if (res.url) {
          if (tab && !isandroid) {
            if (!instant) {
              tabwin.location.href = res.url;
            } else {
              window.open(res.url, "_blank");
            }
          } else if (!isandroid) {
            d.find("iframe").attr("src", res.url);
          } else {
            console.log("vor mobil launch:", res.url);
            //setTimeout(e=>showModal("launch-mobile").setProperties({lnk: res.url}),200);
            setTimeout(e => {
              $("body").append($('<div class="tmpmodal tmpkistamobilelaunch">').append($('<div class="tmpmodaltitle">').text("Raum mobil betreten")).append($('<div class="kista-bbb-mobile-label">').text("Wechsel zum nativen Browser")).append($('<div class="kista-bbb-mobile-link">').append($('<a>').attr({
                "target": "_blank",
                "href": res.url
              }).text("Beitreten"))).append($('<div class="tmpmodalbuttons">').append($('<div class="tmpmodalbtncancel">').text("Schließen").click(e => $(e.target).parents(".tmpmodal").remove()))));
            }, 200);
          }
        }
      }).catch(function (error) {
        (0, _ajaxError.popupAjaxError)(error);
      });
    }
  }
  function fullscreenRequest(element) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
      element.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    } else {
      return;
    }
    document.activeElement.blur();
    element.focus();
  }
  function closeBBB($elem) {
    $elem.find(".kista-bbb-ifdiv,iframe").remove();
    $elem.children().show();
  }
  function testios() {
    var ret = {
      ios: undefined,
      safari: undefined,
      standalone: undefined,
      browser: "unknown"
    };
    if (window.hasOwnProperty("navigator")) {
      if (window.navigator.hasOwnProperty("standalone")) {
        ret.standalone = window.navigator.standalone;
      }
      if (window.navigator.hasOwnProperty("userAgent") || 1) {
        var userAgent = window.navigator.userAgent;
        ret.ios = /iphone|ipod|ipad/i.test(userAgent);
        ret.safari = /safari/i.test(userAgent);
        for (var tst of [[/Firefox/, "Mozilla Firefox"], [/Opera/, "Opera"], [/OPR/, "Opera"], [/Trident/, "Microsoft Internet Explorer"], [/Edge/, "Microsoft Edg"], [/Chrome/, "Google Chrome or Chromium"], [/Safari/, "Apple Safari"]]) {
          if (tst[0].test(userAgent)) {
            ret.browser = tst[1];
            break;
          }
        }
      }
    }
    //ret.isAppWebview=isAppWebview(); // funktioniert seit ~2.9.0beta4 nicht mehr, "[PLUGIN kista-bbb] TypeError: o.isAppWebview is not a function"
    //ret.isAppleDevice=isAppleDevice();
    //ret.iOSWithVisualViewport=iOSWithVisualViewport();
    // ret.isiPad=isiPad();
    //ret.safariHacksDisabled=safariHacksDisabled(); // verursacht in Android Webviews kritischen Fehler: Uncaught TypeError: Cannot read property 'getItem' of null at e.safariHacksDisabled //var pref = localStorage.getItem("safari-hacks-disabled");
    // ret.isiOSPWA=isiOSPWA();
    return ret;
    if (ios) {
      if (!standalone && safari) {
        //browser
      } else if (standalone && !safari) {
        //standalone
      } else if (!standalone && !safari) {
        //uiwebview
      }
      ;
    } else {
      //not iOS
    }
    ;
  }
  function attachButton(helper, $elem) {
    const currentUser = helper.widget.currentUser;
    const dsettings = helper.container.lookup("site-settings:main");
    const data = $elem.data(),
      isDev = false;
    let wlrex = /https?:\/\/[^\/]+/.exec(window.location),
      baseurl = wlrex ? wlrex[0] : "https://lnw.kinderstark.nrw";
    let isiphone = testios().ios,
      isandroid = $(window).height() >= $(window).width() * 1.2;
    let bdiv = $('<div>');
    bdiv.append(`<div class="kista-bbb-upper">Video-Konferenzraum</div><div class='kista-bbb-title'>${$elem.data("titel")}</div>`);
    bdiv.append($('<div class="kista-bbb-status">'));
    if (isDev) {
      var btest = testios(),
        dmode = "Standard";
      if (btest.ios) {
        if (btest.safari) {
          dmode = "iOS Safari";
        } else {
          dmode = "iOS WKWebView";
        }
      }
      bdiv.append($('<div class="kista-bbb-debug">').html(Object.entries(btest).map(x => `${x[0]}: <b>${x[1]}</b>`).join("<br>") + `<br><b>Modus: ${dmode}</b>`));
    }
    bdiv.append($('<div>').append($(`<button class='kista-bbb-launch btn'>${(0, _iconLibrary.iconHTML)("headset")} Jetzt teilnehmen</button>`).on("click", () => launchBBB($elem, $elem.find(".kista-bbb-launchmode").hasClass("kista-bbb-launchmode-tab"), isDev && dmode == "iOS WKWebView"))));
    let $bottombuttons = $('<div class="kista-bbb-launchmode kista-bbb-launchmode-tab">').appendTo(bdiv);
    let $tablelist = $('<table class="kista-bbb-guests-table"><thead><tr><td>Name</td><td>Teilnahmelink</td><td>Eingeladen von</td><td></td></tr></thead></table>');
    // <tr class="kista-bbb-guests-loading"><td colspan="4">Lade Gästeliste...</td></tr>
    // lnw.kinderstark.nrw/plugins/kistaBBB/logout.html
    let resline = (n, u, r) => $(`<tr><td>${n}</td><td><button class='kista-bbb-guest-link btn'>${(0, _iconLibrary.iconHTML)("eye")}<div class='kista-bbb-guest-btntext'> Anzeigen</div></button></td><td>${u}</td><td class="kista-bbb-guests-deltd"><button class='kista-bbb-guest-delete btn'>${(0, _iconLibrary.iconHTML)("times-circle")}<div class='kista-bbb-guest-btntext'> Löschen</div></button></td></tr>`).data(r);
    let tmpshow_guest = o => $("body").append($('<div class="tmpmodal">').append($('<div class="tmpmodaltitle">').text("Teilnahmelink")).append($('<div class="tmpmodalmanual">').text("Gastzugang für")).append($('<div class="kista-bbb-guests-name">').text(o.obj.name)).append($('<div class="kista-bbb-guests-token">').text(`${o.bu}/meeting/gast/${o.obj.token}`)).append($('<div class="tmpmodalbuttons">').append($('<div class="tmpmodalbtncancel">').text("Schließen").click(e => $(e.target).parents(".tmpmodal").remove()))));
    let refreshguests = () => {
      (0, _ajax.ajax)("/kista-bbb/guests", {
        type: "POST",
        data: {
          cmd: "list",
          pkey: $elem.data()["pp"]
        }
      }).then(res => {
        $tablelist.find("tr:not(:first)").remove();
        let ra = [];
        for (let i in res) {
          ra.push(res[i]);
        }
        $guestexpandertext.text("Externe Gäste");
        if (ra.length > 0) {
          $guestexpandertext.text(ra.length + (ra.length == 1 ? " externer Gast" : " externe Gäste"));
        }
        ra.sort((a, b) => a.name > b.name ? 1 : -1);
        ra.forEach(r => $tablelist.append(resline(r.name, r.username, r)));
        $tablelist.find("button.kista-bbb-guest-link").on("click", e => tmpshow_guest({
          obj: $(e.target).parents("tr").data(),
          bu: baseurl
        }));
        //$tablelist.find("button.kista-bbb-guest-link").on("click",e=>showModal("show-guest-invite").setProperties({obj:$(e.target).parents("tr").data(),bu:baseurl}));
        $tablelist.find("button.kista-bbb-guest-delete").on("click", e => (0, _ajax.ajax)("/kista-bbb/guests", {
          type: "POST",
          data: {
            cmd: "delete",
            pkey: $(e.target).parents("tr").data()["pp"],
            token: $(e.target).parents("tr").data()["token"]
          }
        }).then(refreshguests).catch(_ajaxError.popupAjaxError));
        // $tablelist.find("button.kista-bbb-guest-delete").on("click",e=>alert($(e.target).parents("tr").data()["pp"]));
      }).catch(_ajaxError.popupAjaxError);
    };
    let guest_then = e => {
      let cdat = {
        cmd: "create",
        name: e,
        titel: data["titel"],
        pp: data["pp"]
      };
      if (data.hasOwnProperty("sr")) {
        cdat["sr"] = data["sr"];
      }
      (0, _ajax.ajax)("/kista-bbb/guests", {
        type: "POST",
        data: cdat
      }).then(res => {
        if (res.hasOwnProperty("token") && res.hasOwnProperty("name")) {
          refreshguests();
          //showModal("show-guest-invite").setProperties({obj:res,bu:baseurl});
          tmpshow_guest({
            obj: res,
            bu: baseurl
          });
        }
      }).catch(function (error) {
        (0, _ajaxError.popupAjaxError)(error);
      });
    };
    let tmpguestdiag = gt => $("body").append($('<div class="tmpmodal">').append($('<div class="tmpmodaltitle">').text("Gastzugang zur Konferenz hinzufügen")).append($('<div class="tmpmodalmanual">').text("Einladungslink zur Konferenz einrichten.")).append($('<div class="tmpmodalinput">').append($('<div>').text("Name")).append($('<input>').ready(e => $(".tmpmodal input").focus()))).append($('<div class="tmpmodalbuttons">').append($('<div class="tmpmodalbtnok">').text("Speichern").click(e => {
      let x = $(e.target).parents(".tmpmodal").find(".tmpmodalinput>input")[0].value.replace(/^\s*|\s*$/g, "");
      if (x.length) {
        guest_then(x);
        $(e.target).parents(".tmpmodal").remove();
      }
    })).append($('<div class="tmpmodalbtncancel">').text("Abbrechen").click(e => $(e.target).parents(".tmpmodal").remove()))));
    let $guestlist = $('<div class="kista-bbb-guests-list">').append($tablelist).append($(`<button class='kista-bbb-addguest btn'>${(0, _iconLibrary.iconHTML)("plus-circle")} Gast hinzufügen</button>`).on("click", e => tmpguestdiag(guest_then)));
    /*  let $guestlist=$('<div class="kista-bbb-guests-list">').append($tablelist).append($(`<button class='kista-bbb-addguest btn'>${iconHTML("plus-circle")} Gast hinzufügen</button>`).on("click",e=>showModal("add-guest").setProperties({retfunc: e=>{let cdat={cmd:"create",name:e,titel:data["titel"],pp:data["pp"]}; if (data.hasOwnProperty("sr")) {cdat["sr"]=data["sr"]} ajax("/kista-bbb/guests", {
          type: "POST",
          data: cdat,
        })
          .then(res=>{
            if (res.hasOwnProperty("token") && res.hasOwnProperty("name")) {
              refreshguests();
              showModal("show-guest-invite").setProperties({obj:res,bu:baseurl});
            }
          })
          .catch(function(error) {
            popupAjaxError(error);
          })},})));
    */
    //if (isDev && btest.dmode=="iOS WKWebView") { } else {
    if (1) {
      $bottombuttons.append($('<div class="kista-bbb-launchmode-text kista-bbb-launchmode-text-tab">').text("Neues Tab").on("click", e => $(e.target).parent().addClass("kista-bbb-launchmode-tab"))).append($('<div class="kista-bbb-switch">').on("click", e => $(e.target).parent().toggleClass("kista-bbb-launchmode-tab"))).append($('<div class="kista-bbb-launchmode-text kista-bbb-launchmode-text-notab">').text("Bild im Bild").on("click", e => $(e.target).parent().removeClass("kista-bbb-launchmode-tab")));
    }
    if (isandroid) {
      $bottombuttons.find(".kista-bbb-launchmode-text,.kista-bbb-switch").css("visibility", "hidden");
      $bottombuttons.removeClass("kista-bbb-launchmode-tab");
    }
    let $guestexpandertext = $('<div class="kista-bbb-guests-text">').text("Gäste");
    let invgroups = dsettings.kista_bbb_invite_groups;
    invgroups = invgroups.length ? invgroups.split("|").map(Number) : [];
    if (invgroups.length == 0 || currentUser.groups.map(e => e.id).filter(e => invgroups.indexOf(e) > -1).length) {
      $bottombuttons.append($('<div class="kista-bbb-guests-div">').append((0, _iconLibrary.iconHTML)("user-plus")).append($guestexpandertext).on("click", e => $guestlist.toggle()));
      bdiv.append($guestlist);
      refreshguests();
    }
    $elem.empty().append($('<div class="kista-bbb-panel">').append(bdiv));
  }
  function attachStatus($elem, helper) {
    const status = $elem.find(".kista-bbb-status");
    const data = $elem.data();

    //ajax(`/kista-bbb/status/${data.meetingID}.json`).then((res) => {
    (0, _ajax.ajax)(`/kista-bbb/status.json`, {
      type: "POST",
      data: {
        titel: data["titel"]
      }
    }).then(res => {
      if (res.avatars) {
        status.html(`<span>Teilnehmer: </span>`);
        res.avatars.forEach(function (avatar) {
          status.append(`<img src="${avatar.avatar_url}" class="avatar" width="25" height="25" title="${avatar.name}" />`);
        });
      }
    });
  }
  function attachBBB($elem, helper) {
    if (helper) {
      $elem.find("[data-wrap=bbb]").each((idx, val) => {
        console.log("helper", helper);
        attachButton(helper, $(val));
        attachStatus($(val), helper);
      });
    }
  }
  function initializeKistaBbb(api) {
    // https://github.com/discourse/discourse/blob/master/app/assets/javascripts/discourse/lib/plugin-api.js.es6
    const currentUser = api.getCurrentUser();
    const siteSettings = api.container.lookup("site-settings:main");
    const altSettings = {
      "kista_bbb_staff_only": true
    };
    window.cu = currentUser;
    api.decorateCooked(attachBBB, {
      id: "kista-bbb"
    }); // https://github.com/jitsi/jitsi-meet/issues/7231#issuecomment-657210849//++++TODO decorateCooked wird deprecated, prüfen ob decorateCookedElement geht https://github.com/discourse/discourse/blob/bbe5d8d5cf1220165842985c0e2cd4c454d501cd/app/assets/javascripts/discourse/app/lib/plugin-api.js#L210

    let tmpinsertdiag = te => $("body").append($('<div class="tmpmodal">').append($('<div class="tmpmodaltitle">').text("Live-Konferenz")).append($('<div class="tmpmodalmanual">').text("Konferenzraum einfügen")).append($('<div class="tmpmodalinput">').append($('<div>').text("Titel")).append($('<input>').ready(e => $(".tmpmodal input").focus()))).append($('<div class="tmpmodalbuttons">').append($('<div class="tmpmodalbtnok">').text("Speichern und einfügen").click(e => {
      let x = $(e.target).parents(".tmpmodal").find(".tmpmodalinput>input")[0].value.replace(/^\s*|\s*$/g, "");
      if (x.length) {
        let genpw = () => Math.random().toString(36).slice(-8);
        te.addText(`[wrap=bbb Titel="${x}" pp="${genpw()}-${genpw()}"][/wrap]`);
        $(e.target).parents(".tmpmodal").remove();
      }
    })).append($('<div class="tmpmodalbtncancel">').text("Abbrechen").click(e => $(e.target).parents(".tmpmodal").remove()))));
    if (!altSettings.bbb_staff_only || alt.kista_bbb_staff_only && currentUser && currentUser.staff) {
      /*
          api.modifyClass("controller:composer", {
            actions: {
              insertBBBModal() {
                tmpinsertdiag(this.get("toolbarEvent"));
                // showModal("insert-bbb").setProperties({
                //   toolbarEvent: this.get("toolbarEvent"),
                //   apiobj:api,
                // });
              },
            },
          });
      */

      //https://meta.discourse.org/t/developer-s-guide-to-discourse-themes/93648#heading--4-c-13
      //api.onToolbarCreate(function(toolbar) { toolbar.addButton({ ...
      /*
      api.addToolbarPopupMenuOptionsCallback((controller) => {
        return {
          id: "insert-bbb",
          icon: "headset",
          action: "insertBBBModal",
          label: "kista_bbb.insertlabel",
        };
      });
      */
      api.addComposerToolbarPopupMenuOption({
        id: "insert-bbb",
        icon: "headset",
        action: toolbarEvent => tmpinsertdiag(toolbarEvent),
        label: "kista_bbb.insertlabel"
      });
    }
  }
  var _default = _exports.default = {
    name: "kista-bbb",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.13.0", initializeKistaBbb);
    }
  };
});