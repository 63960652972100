define("discourse/plugins/kista_bbb/lib/discourse-markdown/kista-bbb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;
  /*
  import { registerOption } from "pretty-text/pretty-text";
  
  registerOption((siteSettings, opts) => {
    opts.features["kista-bbb"] = !!siteSettings.kista_bbb_enabled;
  });
  */
  const CONTAINS_BLOCK_REGEX = /\n|<img|!\[[^\]]*\][(\[]/;
  function insertBbb(_, spoiler) {
    const element = CONTAINS_BLOCK_REGEX.test(spoiler) ? "div" : "span";
    return `<${element} class='bbb'>${spoiler}</${element}>`;
  }
  function replaceBbb(text) {
    text = text || "";
    while (text !== (text = text.replace(/\[bbb\]((?:(?!\[bbb\]|\[\/bbb\])[\S\s])*)\[\/bbb\]/gi, insertSpoiler))) {}
    return text;
  }
  function setupMarkdownIt(helper) {
    helper.registerOptions((opts, siteSettings) => {
      opts.features["kista-bbb"] = !!siteSettings.kista_bbb_enabled;
    });
    helper.registerPlugin(md => {
      md.inline.bbcode.ruler.push("bbb", {
        tag: "bbb",
        wrap: "span.bbb"
      });
      md.block.bbcode.ruler.push("bbb", {
        tag: "bbb",
        wrap: "div.bbb"
      });
    });
  }
  function setup(helper) {
    helper.allowList(["span.bbb", "div.bbb"]);
    if (helper.markdownIt) {
      setupMarkdownIt(helper);
    } else {
      helper.addPreProcessor(replaceSpoilers);
    }
  }
});