define("discourse/plugins/kista_bbb/discourse/templates/actions-index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    controller-index.hbs
  
  */
  {
    "id": "gvSgElOL",
    "block": "[[[1,\"controller-index.hbs\\n\"]],[],false,[]]",
    "moduleName": "discourse/plugins/kista_bbb/discourse/templates/actions-index.hbs",
    "isStrictMode": false
  });
});