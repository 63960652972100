define("discourse/plugins/kista_bbb/discourse/templates/modal/launch-mobile", ["exports", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "@ember/template-factory"], function (_exports, _thisFallbackHelper, _tryLookupHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#d-modal-body title="kista_bbb.mobilelaunch.title" class="koord-bbb-showinvite"}}
  <div class="bbb-mobile-launch">
    <div class="kista-bbb-mobile-label">{{i18n "kista_bbb.mobilelaunch.label"}}</div>
    <div class="kista-bbb-mobile-link"><a href="{{ll}}" target="_blank">{{i18n "kista_bbb.mobilelaunch.link"}}</a></div>
  </div>
  {{/d-modal-body}}
  
  <div class="modal-footer">
  {{d-button class="btn-primary" label="kista_bbb.mobilelaunch.ok" action=(action "okclose")}}
  </div>
  
  */
  {
    "id": "om5A3O7A",
    "block": "[[[6,[39,0],null,[[\"title\",\"class\"],[\"kista_bbb.mobilelaunch.title\",\"koord-bbb-showinvite\"]],[[\"default\"],[[[[10,0],[14,0,\"bbb-mobile-launch\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"kista-bbb-mobile-label\"],[12],[1,[28,[35,1],[\"kista_bbb.mobilelaunch.label\"],null]],[13],[1,\"\\n  \"],[10,0],[14,0,\"kista-bbb-mobile-link\"],[12],[44,[[28,[37,3],null,[[\"ll\"],[[28,[32,0],[\"ll\"],null]]]]],[[[10,3],[15,6,[29,[[52,[30,1,[\"ll\"]],[28,[30,1,[\"ll\"]],null,null],[28,[32,1],[[30,0],\"ll\",\"[\\\"The `ll` property path was used in the `discourse/plugins/kista_bbb/discourse/templates/modal/launch-mobile.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.ll}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]]],[14,\"target\",\"_blank\"],[12],[1,[28,[35,1],[\"kista_bbb.mobilelaunch.link\"],null]],[13]],[1]]],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[10,0],[14,0,\"modal-footer\"],[12],[1,\"\\n\"],[1,[28,[35,5],null,[[\"class\",\"label\",\"action\"],[\"btn-primary\",\"kista_bbb.mobilelaunch.ok\",[28,[37,6],[[30,0],\"okclose\"],null]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"maybeHelpers\"],false,[\"d-modal-body\",\"i18n\",\"let\",\"hash\",\"if\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/kista_bbb/discourse/templates/modal/launch-mobile.hbs",
    "scope": () => [_tryLookupHelper.default, _thisFallbackHelper.default],
    "isStrictMode": false
  });
});