define("discourse/plugins/kista_bbb/discourse/routes/bbbrec", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Route for the path `/notebook` as defined in `../notebook-route-map.js.es6`.
   */
  var _default = _exports.default = Discourse.Route.extend({
    renderTemplate() {
      // Renders the template `../templates/bbbrec.hbs`
      this.render('bbbrec');
    }
  });
});