define("discourse/plugins/kista_bbb/discourse/kista-bbb-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("kista-bbb", function () {
      this.route("actions", function () {
        this.route("show", {
          path: "/:id"
        });
      });
    });
    this.route("bbbrec", function () {
      this.route("bbbrec", {
        path: "/bbbrec"
      });
    });
  }
  ;
});