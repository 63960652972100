define("discourse/plugins/kista_bbb/kista-bbb/controllers/show-guest-invite", ["exports", "@ember/controller", "discourse/mixins/modal-functionality", "discourse-common/utils/decorators", "@ember/utils"], function (_exports, _controller, _modalFunctionality, _decorators, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, {
    keyDown(e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    },
    onShow() {
      this.setProperties({
        obj: this.obj,
        bu: this.bu
        // group: this.fullgroupname,
      });
      // this.setProperties({
      //    server: this.get("servers.firstObject.value"),
      // });
    },
    actions: {
      okclose() {
        this.send("closeModal");
      }
    }
  });
});