define("discourse/plugins/kista_bbb/discourse/templates/bbbrec/bbbrec", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h1>Notebook2</h1>
  */
  {
    "id": "lwvpHTkq",
    "block": "[[[10,\"h1\"],[12],[1,\"Notebook2\"],[13]],[],false,[]]",
    "moduleName": "discourse/plugins/kista_bbb/discourse/templates/bbbrec/bbbrec.hbs",
    "isStrictMode": false
  });
});