define("discourse/plugins/kista_bbb/kista-bbb/controllers/add-guest", ["exports", "@ember/controller", "discourse/mixins/modal-functionality", "discourse-common/utils/decorators", "@ember/utils"], function (_exports, _controller, _modalFunctionality, _decorators, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, (_dec = (0, _decorators.default)("name"), (_obj = {
    keyDown(e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    },
    onShow() {
      this.setProperties({
        // group: this.fullgroupname,
      });
      this.setProperties({
        // server: this.get("servers.firstObject.value"),
      });
    },
    saveDisabled(name) {
      return (0, _utils.isEmpty)(name) || /[\?]/i.test(name); // || !/^2[0-9]{3}-[0-9]{2}-[0-9]{2}$/.test(validto);
    },
    actions: {
      resend() {
        this.send("closeModal");
      },
      //@discourseComputed("name")
      save() {
        this.retfunc(this.name);
        this.name = "";
        this.send("closeModal");
      },
      deactivate() {
        this.name = "";
        this.send("closeModal");
      },
      cancel() {
        this.send("closeModal");
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "saveDisabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "saveDisabled"), _obj)), _obj)));
});